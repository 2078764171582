import React from 'react'
import { graphql } from 'gatsby'
import { PortableText } from '@portabletext/react'
import Marks from '../components/portable-text/marks';

import Seo from '../components/seo'
import Layout from '../components/layout'
import Container from '../components/container'
import Header from '../components/Header'
import Footer from '../components/Footer'
import PageBuilder from '../components/PageBuilder'

import clsx from 'clsx';

const PageTemplate = props => {
  const {
    data
  } = props

  const {
    title,
    lead,
    pageBuilder,
    _rawPageBuilder
  } = data?.page

  const {
    disclaimer
  } = data?.settings

  return (
    <Layout>
      <Seo title={title} />
      <Container className={clsx(
        `[--vkd-underline-color:var(--vkd-orange-dark)]`,
        `bg-orange mb-[32px] md:mb-[100px]`
      )} stretch={true}>
        <Header className={`mb-[32px] md:mb-[80px]`} />
        <Container className={`pb-[30px] md:pb-[70px]`}>
          <div className={`max-w-prose`}>
            <h1 className={`text-title md:text-title-md font-bold mb-[30px]`}>
              {title}
            </h1>
            <div className={`text-lead md:text-lead-md`}>
              <PortableText value={lead} components={{
                marks: Marks
              }} />
            </div>
          </div>
        </Container>
        {disclaimer && (
          <section className={`bg-orange-dark text-white text-center`}>
            <Container className={`py-[15px] md:py-[24px]`}>
              {disclaimer}
            </Container>
          </section>
        )}
      </Container>

      <PageBuilder
        pageBuilder={pageBuilder}
        _rawPageBuilder={_rawPageBuilder}
      />

      <Footer />
    </Layout>
  )
}

export default PageTemplate

export const query = graphql`
  query PageQuery($id: String!) {
    page: sanityPage(id: {eq: $id}) {
      title,
      lead: _rawLead(resolveReferences: {maxDepth: 10}),
      ...PageBuilderFields
    },
    settings: sanitySettings {
      disclaimer
    }
  }
`
